<template>
  <div>
  <CRow>
      <CCol xs="12">
        <CCard>
          <CCardBody>
            <CCardTitle><h1>Te damos la bienvenida a Orderly Facturador</h1></CCardTitle>
            <CAlert color="danger">
              <strong><h2>¡Emite tu primera factura en menos de 5 minutos!</h2></strong>
            </CAlert>
          </CCardBody>
          <CCardBody>
            <CCardText>
              <CRow style="min-height: 500px;">
                <CCol xs="12" lg="6" xl="3">
                  <CCard border-color="warning" style="min-height: 600px;">
                    <CCardHeader color="danger" text-color="white">
                      Información Fiscal
                    </CCardHeader>
                    <CCardBody>
                        💡  RFC, Razón social, domicilio fiscal, régimen.
                        <br /><br />
                        💡 Estos son <b>INDISPENSABLES</b> para poder emitir una factura.
                        <br /><br />
                        👍🏻 Registra estos datos tal y como están en tu constancia de situación fiscal. Esto evitará errores en los tus datos de emisor.</li>
                        <br /><br />
                        ⚠️ Nivel de importancia: 10/10
                      <br /><br /><br />
                      <CListGroup flush>
                        <CListGroupItem>
                        <CButton :color="this.analytics.fiscal ? 'success' : 'default'" size="sm" style="float: left; margin-right: 10px; height: 30px;" @click="link('fiscal')"><CIcon :name="this.analytics.fiscal ? 'cil-check' : 'cil-warning'"/></CButton>
                          Información Fiscal
                          <CButton color="danger" size="sm" style="float: right; height: 30px;" @click="link('fiscal')">Configurar</CButton>
                        </CListGroupItem>
                      </CListGroup>
                    </CCardBody>
                    <CButton color="danger" size="sm" style="height: 30px; width: 90%; bottom: 10px; vertical-align: bottom; margin: 5%;" @click="tutorial(1)"><CIcon name="cil-movie"/> Ver Tutorial</CButton>
                  </CCard>
                </CCol>
                <CCol xs="12" lg="6" xl="3">
                  <CCard border-color="warning" style="min-height: 600px;">
                    <CCardHeader color="warning" text-color="white">
                      Certificado de Sello Digital
                    </CCardHeader>
                    <CCardBody>
                        💡 .CER, .KEY y contraseña de tus sellos.
                        <br /><br />
                        💡 Estos son <b>INDISPENSABLES</b> para poder emitir una factura.
                        <br /><br />
                        👍🏻 Si tienes dudas de cómo generar estos certificados tu contador podrá ayudarte.
                        <br /><br />
                        ⚠️ Nivel de importancia: 10/10
                      <br /><br /><br />
                      <CListGroup flush>
                        <CListGroupItem>
                        <CButton :color="this.analytics.certificates ? 'success' : 'default'" size="sm" style="float: left; margin-right: 10px; height: 30px;" @click="link('certificates')"><CIcon :name="this.analytics.certificates ? 'cil-check' : 'cil-warning'"/></CButton>
                          Certificados
                          <CButton color="warning" size="sm" style="float: right; height: 30px;" @click="link('certificates')">Configurar</CButton>
                        </CListGroupItem>
                        <CListGroupItem>
                        <CButton :color="this.analytics.serials ? 'success' : 'default'" size="sm" style="float: left; margin-right: 10px; height: 30px;" @click="link('serials')"><CIcon :name="this.analytics.serials ? 'cil-check' : 'cil-warning'"/></CButton>
                          Series y Folios
                          <CButton color="warning" size="sm" style="float: right; height: 30px;" @click="link('serials')">Configurar</CButton>
                        </CListGroupItem>
                      </CListGroup>
                    </CCardBody>
                    <CButton color="warning" size="sm" style="height: 30px; width: 90%; bottom: 10px; vertical-align: bottom; margin: 5%;" @click="tutorial(2)"><CIcon name="cil-movie"/> Ver Tutorial</CButton>
                  </CCard>
                </CCol>
                <CCol xs="12" lg="6" xl="3">
                  <CCard border-color="info" style="min-height: 600px;">
                    <CCardHeader color="info" text-color="white">
                      Personaliza tu Facturación
                    </CCardHeader>
                    <CCardBody>
                        💡 Configura tu facturador de acuerdo a la actividad que realizas: selecciona tus unidades, impuestos, monedas y productos y servicios desde los catálogos del SAT.
                        <br /><br />
                        👍🏻 Elige el código del SAT que más se apegue a tu actividad.
                        <br /><br />
                        ⚠️ Nivel de importancia: 9/10
                      <br /><br />
                      <CListGroup flush>
                        <CListGroupItem>
                          <CButton :color="this.analytics.units ? 'success' : 'default'" size="sm" style="float: left; margin-right: 10px; height: 30px;" @click="link('units')"><CIcon :name="this.analytics.units ? 'cil-check' : 'cil-warning'"/></CButton>
                          Unidades
                          <CButton color="info" size="sm" style="float: right; height: 30px;" @click="link('units')">Configurar</CButton>
                        </CListGroupItem>
                        <CListGroupItem>
                        <CButton :color="this.analytics.taxes ? 'success' : 'default'" size="sm" style="float: left; margin-right: 10px; height: 30px;" @click="link('taxes')"><CIcon :name="this.analytics.taxes ? 'cil-check' : 'cil-warning'"/></CButton>
                          Impuestos
                          <CButton color="info" size="sm" style="float: right; height: 30px;" @click="link('taxes')">Configurar</CButton>
                        </CListGroupItem>
                        <CListGroupItem>
                          <CButton :color="this.analytics.currencies ? 'success' : 'default'" size="sm" style="float: left; margin-right: 10px; height: 30px;" @click="link('currencies')"><CIcon :name="this.analytics.currencies ? 'cil-check' : 'cil-warning'"/></CButton>
                          Monedas
                          <CButton color="info" size="sm" style="float: right; height: 30px;" @click="link('currencies')">Configurar</CButton>
                        </CListGroupItem>
                        <CListGroupItem>
                          <CButton :color="this.analytics.fiscal_products ? 'success' : 'default'" size="sm" style="float: left; margin-right: 10px; height: 30px;" @click="link('fiscal-products')"><CIcon :name="this.analytics.fiscal_products ? 'cil-check' : 'cil-warning'"/></CButton>
                          Productos y Servicios
                          <CButton color="info" size="sm" style="float: right; height: 30px;" @click="link('fiscal-products')">Configurar</CButton>
                        </CListGroupItem>
                      </CListGroup>
                    </CCardBody>
                    <CButton color="info" size="sm" style="height: 30px; width: 90%; bottom: 10px; vertical-align: bottom; margin: 5%;" @click="tutorial(3)"><CIcon name="cil-movie"/> Ver Tutorial</CButton>
                  </CCard>
                </CCol>
                <CCol xs="12" lg="6" xl="3">
                  <CCard border-color="success" style="min-height: 600px;">
                    <CCardHeader color="success"  text-color="white">
                      Agiliza tu Facturación
                    </CCardHeader>
                    <CCardBody>
                        💡 Agiliza tu facturación creando tu catálogo de clientes, productos y servicios recurrentes.
                        <br /><br />
                        👍🏻 Subir la información de tus clientes tal como esta en su constancia de situación fiscal te evitará errores en tus facturas.
                        <br /><br />
                        👍🏻 Configura tus productos, esto te evitará hacerlo manual y tu facturación será fácil y rápida.
                        <br /><br />
                        ⚠️ Nivel de importancia: 9/10
                      <br /><br /><br />
                      <CListGroup flush>
                        <CListGroupItem>
                          <CButton :color="this.analytics.customers ? 'success' : 'default'" size="sm" style="float: left; margin-right: 10px; height: 30px;" @click="link('customers')"><CIcon :name="this.analytics.customers ? 'cil-check' : 'cil-warning'"/></CButton>
                          Clientes
                          <CButton color="success" size="sm" style="float: right; height: 30px;" @click="link('customers')">Configurar</CButton>
                        </CListGroupItem>
                        <CListGroupItem>
                          <CButton :color="this.analytics.products ? 'success' : 'default'" size="sm" style="float: left; margin-right: 10px; height: 30px;" @click="link('products')"><CIcon :name="this.analytics.products ? 'cil-check' : 'cil-warning'"/></CButton>
                          Productos
                          <CButton color="success" size="sm" style="float: right; height: 30px;" @click="link('products')">Configurar</CButton>
                        </CListGroupItem>
                      </CListGroup>
                    </CCardBody>
                    <CButton color="success" size="sm" style="height: 30px; width: 90%; bottom: 10px; vertical-align: bottom; margin: 5%;" @click="tutorial(4)"><CIcon name="cil-movie"/> Ver Tutorial</CButton>
                  </CCard>
                </CCol>
              </CRow>
            </CCardText>
          </CCardBody>
          <br /><br /><br />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import DashboardChart from './charts/DashboardChart'
import WidgetsDropdown from './widgets/WidgetsDropdown'
import WidgetsBrand from './widgets/WidgetsBrand'

import ws from '../services/account';

export default {
  name: 'Onboarding',
  components: {
    DashboardChart,
    WidgetsDropdown,
    WidgetsBrand
  },
  data () {
    return {
      analytics: false
    }
  },
  mounted: async function() {
    let response = await ws.getAnalytics();

    this.analytics = response.data;
  },
  methods: {
    async totals (totals){
      this.countInvoices = totals[0];
      this.totalInvoices = totals[1];
      this.averageInvoices = totals[2];
      this.totalPayments = totals[3];
    },
    link(section){
      window.location.href = "/#/"+section;
    },
    tutorial(step){
      switch(step){
        case 1: window.open('https://www.youtube.com/watch?v=_6o8i1_USP0', '_blank');  break;
        case 2: window.open('https://www.youtube.com/watch?v=4XwyoE7MyvI', '_blank');  break;
        case 3: window.open('https://www.youtube.com/watch?v=bYUCzmwmXgM', '_blank');  break;
        case 4: window.open('https://www.youtube.com/watch?v=YUFh3NCDy9g', '_blank');  break;
      }
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  }
}
</script>
